import { graphql, Link, useStaticQuery } from "gatsby"
import React from "react"

const Layout = ({ isHomePage, children }) => {
  const {
    wp: {
      generalSettings: { title },
    },
  } = useStaticQuery(graphql`
    query LayoutQuery {
      wp {
        generalSettings {
          title
          description
        }
      }
    }
  `)

  return (
    <div
      className="bg-black d-flex flex-column p-4"
      data-is-root-path={isHomePage}
      style={{ minHeight: "100vh" }}
    >
      <header className="global-header">
        <Link className="site-logo" to="/">
          {title}
        </Link>
      </header>

      <main className="d-flex align-items-center flex-grow-1 mb-5">
        {children}
      </main>

      <footer className="global-footer">
        <div className="container">
          <div className="footer-content">
            <div className="footer-logo">SiteCraft</div>
            <a
              href="mailto:info@sitecraft.com"
              target="_blank"
              rel="noreferrer"
            >
              info@sitecraft.com
            </a>
            <div>
              230 Park Ave
              <br />
              Floors 3 and 4 West
              <br />
              New York, NY 10169
            </div>
            <div className="small">© {new Date().getFullYear()} SiteCraft</div>
          </div>
        </div>
      </footer>
    </div>
  )
}

export default Layout
